import { BehaviorSubject, Observable, Subject, Subscription, of } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';

export class ValueDebouncer<T extends any = any> {

  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  static defaultDebouncingValue = 500;

  protected _valueBehaviourSubject: BehaviorSubject<T>;
  protected _pausedBehaviourSubject = new BehaviorSubject<boolean>(false);

  protected ignoreCounter = 0;

  subscription: Subscription;

  constructor(startValue?: T) {
    this._valueBehaviourSubject = new BehaviorSubject(startValue);
  }

  get value(): T {
    return this.getValue();
  }

  set value(value: T) {
    this.setValue(value);
  }

  get value$(): Observable<T> {
    return this._valueBehaviourSubject.pipe(
      filter(() => {
        const passValue = !this._pausedBehaviourSubject.value && this.ignoreCounter === 0;
        if (!passValue) {
          this.ignoreCounter = Math.max(0, this.ignoreCounter - 1);
        }
        return passValue;
      })
    );
  }

  get paused$(): Observable<boolean> {
    return this._pausedBehaviourSubject.asObservable();
  }

  get isPaused(): boolean {
    return this._pausedBehaviourSubject.value;
  }

  getDebouncingObservable(ms: number = ValueDebouncer.defaultDebouncingValue) {
    return this.value$.pipe(debounceTime(ms));
  }

  /**
   * pauses observing the stream but will keep the data stream updated so that continue() / reactivate() works with an updated stream
   */
  pause(delay?: number) {
    this.setPause(true, delay);
  }

  /**
   * reactivate() is like newly subscribing to observable (it will trigger or start debounce timer immidiatly) but with the last set value
   * Note: necessary if the stream needs to be updated directly after continuing
   */
  reactivate(delay?: number) {
    this.setPause(false, delay).subscribe(() => this._valueBehaviourSubject.next(this._valueBehaviourSubject.value));
  }

  /**
   * continues observing the stream and triggers when the next data enters
   * Note: used if the stream does not need to be updated directly after continuing
   */
  continue(delay?: number) {
    this.setPause(false, delay);
  }

  private setPause(value: boolean, delay?: number) {
    const subj = new Subject<void>();
    if (typeof delay === 'number') {
      setTimeout(()=> {
        this._pausedBehaviourSubject.next(value);
        subj.next();
        subj.complete();
      }, delay);
    } else {
      this._pausedBehaviourSubject.next(value);
      subj.complete();
      return of(void 0);
    }
    return subj.asObservable();
  }

  setValue(value: T) {
    this._valueBehaviourSubject.next(value);
  }

  getValue(): T {
    return this._valueBehaviourSubject.value;
  }

  unsubscribe() {
    this.subscription?.unsubscribe();
  }

}


export interface DebouncableClassContructor<T extends any = any> {
  new(value?: any): T;
  toString(): string;
  valueOf(): T;
}


export class InputValueDebouncer<T extends any = any> extends ValueDebouncer<T> {

  constructor(startValue?: T, public typeConstructor: DebouncableClassContructor = Number) {
    super(startValue);
  }

  get bindableValue(): string {
    return(new (this.typeConstructor)(this.getValue()))?.toString();
  }

  set bindableValue(value: string) {
    const original = (new (this.typeConstructor)(value))?.valueOf() as T;
    this.setValue(original);
  }

}
